import React from 'react';
import { Link } from 'react-router-dom';

const AddProductsSection = () => {
  return (
    <section
      id="section2"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      <div className="container">
        <h2 className="display-5">Add Products</h2>
        <p className="lead">
          Add produts that will show up on caycollective after review
        </p>
        <Link to="/add-products" className="btn btn-success">
          Add Products
        </Link>
      </div>
    </section>
  );
};

export default AddProductsSection;