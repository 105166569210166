import React from 'react';
import { Link } from 'react-router-dom';

const OrdersSection = () => {
  return (
    <section
      id="section7"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      <div className="container">
        <h2 className="display-5">Orders</h2>
        <p className="lead">
          Track and fulfill orders efficiently.
        </p>
        <Link to="/orders" className="btn btn-primary">View Orders</Link>
      </div>
    </section>
  );
};

export default OrdersSection;