import React from 'react';

export const TermsOfServicePage = () => {
  const sectionStyle = {
    marginBottom: '2rem',
  };

  const headingStyle = {
    marginBottom: '0.5rem',
    fontWeight: 'bold',
  };

  const subHeadingStyle = {
    marginBottom: '0.5rem',
    fontWeight: 'bold',
    fontSize: '1rem',
  };

  const paragraphStyle = {
    marginBottom: '1rem',
    lineHeight: '1.6',
  };

  const listStyle = {
    paddingLeft: '1.2rem',
    marginBottom: '1rem',
  };

  const listItemStyle = {
    marginBottom: '0.5rem',
  };

  return (
    <div className="container my-5" style={{ maxWidth: '800px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '2rem' }}>
        Terms of Service
      </h2>
      <p style={paragraphStyle}>
        <strong>Last Updated:</strong> [Insert Date]
      </p>
      <p style={paragraphStyle}>
        Welcome to [Marketplace Name] (“we,” “us,” or “our”). These Terms of
        Service (“Terms”) govern your access to and use of our online
        marketplace platform (the “Marketplace”). By accessing or using the
        Marketplace, you (“Seller,” “you,” or “your”) agree to be bound by these
        Terms. If you do not agree to all the Terms, please do not use the
        Marketplace.
      </p>

      {/* Section 1 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>1. Acceptance of Terms</h3>
        <p style={paragraphStyle}>
          By registering as a Seller on the Marketplace, you agree to comply
          with and be bound by these Terms and our Privacy Policy, which is
          incorporated herein by reference.
        </p>
      </div>

      {/* Section 2 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>2. Definitions</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Buyer:</strong> A user who purchases products listed by
            Sellers on the Marketplace.
          </li>
          <li style={listItemStyle}>
            <strong>Seller:</strong> A user who lists and sells products on the
            Marketplace.
          </li>
          <li style={listItemStyle}>
            <strong>User:</strong> Any individual or entity using the
            Marketplace, including both Buyers and Sellers.
          </li>
          <li style={listItemStyle}>
            <strong>Products:</strong> Self-manufactured items that Sellers list
            for sale on the Marketplace.
          </li>
        </ul>
      </div>

      {/* Section 3 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>3. Eligibility</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            You must be at least 18 years old and have the legal capacity to
            enter into a binding contract.
          </li>
          <li style={listItemStyle}>
            By registering, you represent and warrant that all information you
            provide is accurate and complete.
          </li>
        </ul>
      </div>

      {/* Section 4 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>4. Seller Obligations</h3>

        <div style={{ marginLeft: '1rem' }}>
          <h4 style={subHeadingStyle}>4.1 Product Listings</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Accuracy:</strong> All product descriptions, images, and
              other content must be accurate, complete, and not misleading.
            </li>
            <li style={listItemStyle}>
              <strong>Compliance:</strong> Products must comply with all
              applicable laws, regulations, and Marketplace policies.
            </li>
            <li style={listItemStyle}>
              <strong>Exclusivity for Single-Unit Products:</strong> Products
              manufactured in a single unit are intended solely for sale via the
              Marketplace unless otherwise agreed in writing. Sellers may
              terminate this exclusivity by providing a 5-business-day written
              notice.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>4.2 Shipping and Fulfillment</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Timely Shipping:</strong> Products must be shipped within 7
              days of the Buyer’s purchase or within the timeframe specified in
              the product description.
            </li>
            <li style={listItemStyle}>
              <strong>Packaging:</strong> Products must be packaged securely to
              prevent damage during transit.
            </li>
            <li style={listItemStyle}>
              <strong>Shipping Responsibility:</strong> Sellers are responsible
              for shipping the product to the Buyer and bear the risk of loss
              until delivery.
            </li>
            <li style={listItemStyle}>
              <strong>Lost or Damaged Items:</strong> Sellers are responsible for
              any items lost or damaged during shipping and must cover the costs
              associated with such incidents.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>4.3 Returns and Refunds</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Return Policy Compliance:</strong> Sellers must accept
              returns and process refunds in accordance with our Return Policy
              and applicable consumer protection laws.
            </li>
            <li style={listItemStyle}>
              <strong>Notification of Delays:</strong> If unable to ship within
              the specified timeframe, Sellers must inform the Marketplace
              immediately.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>4.4 Legal Compliance</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Laws and Regulations:</strong> Sellers must comply with all
              local, national, and international laws and regulations relevant to
              the sale of their products.
            </li>
            <li style={listItemStyle}>
              <strong>Taxes:</strong> Sellers are responsible for determining
              and paying any taxes applicable to their sales.
            </li>
          </ul>
        </div>
      </div>

      {/* Section 5 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>5. Fees and Payments</h3>

        <div style={{ marginLeft: '1rem' }}>
          <h4 style={subHeadingStyle}>5.1 Commission and Fees</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Commission Rate:</strong> The Marketplace charges a
              commission of 20% of the selling price plus applicable VAT.
            </li>
            <li style={listItemStyle}>
              <strong>Payment Processing:</strong> The Marketplace acts as an
              intermediary for payments between Buyers and Sellers.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>5.2 Payouts</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Payment Schedule:</strong> Sales revenue, minus our
              commission and any applicable fees, will be paid out to Sellers on
              the 25th day of the month following the month of the sale.
            </li>
            <li style={listItemStyle}>
              <strong>Adjustments for Returns:</strong> In the case of returns or
              refunds, the payout amount will be adjusted accordingly in the next
              payout cycle.
            </li>
          </ul>
        </div>
      </div>

      {/* Section 6 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>6. License to Use Content</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Marketing Rights:</strong> Sellers grant the Marketplace a
            worldwide, royalty-free, non-exclusive license to use, reproduce,
            modify, and display product images, descriptions, and related content
            for marketing and promotional purposes.
          </li>
          <li style={listItemStyle}>
            <strong>Seller Representations:</strong> Sellers represent and
            warrant that they own or have the necessary rights to the content
            provided and that such content does not infringe any third-party
            rights.
          </li>
        </ul>
      </div>

      {/* Section 7 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>7. Intellectual Property Rights</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Ownership:</strong> Sellers retain all intellectual property
            rights to their content.
          </li>
          <li style={listItemStyle}>
            <strong>Prohibited Actions:</strong> Users may not infringe upon the
            intellectual property rights of the Marketplace or other Users.
          </li>
        </ul>
      </div>

      {/* Section 8 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>8. Marketing and Promotion</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Seller Promotion:</strong> Sellers agree to reference the
            Marketplace on their social media profiles and other promotional
            channels.
          </li>
          <li style={listItemStyle}>
            <strong>Marketplace Promotion:</strong> The Marketplace may feature
            Sellers and their products in marketing materials without additional
            compensation.
          </li>
        </ul>
      </div>

      {/* Section 9 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>9. Confidentiality and Data Protection</h3>

        <div style={{ marginLeft: '1rem' }}>
          <h4 style={subHeadingStyle}>9.1 Confidential Information</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Definition:</strong> All non-public information exchanged
              between the parties, including the terms of this agreement,
              customer data, and business practices.
            </li>
            <li style={listItemStyle}>
              <strong>Obligations:</strong> Sellers must maintain the
              confidentiality of Confidential Information and not disclose it to
              third parties without prior written consent.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>9.2 Data Protection</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              <strong>Compliance with Laws:</strong> Sellers must comply with all
              applicable data protection laws, including GDPR.
            </li>
            <li style={listItemStyle}>
              <strong>Use of Customer Data:</strong> Customer information may
              only be used for fulfilling orders and must not be shared or used
              for unauthorized purposes.
            </li>
          </ul>
        </div>
      </div>

      {/* Section 10 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>10. Term and Termination</h3>

        <div style={{ marginLeft: '1rem' }}>
          <h4 style={subHeadingStyle}>10.1 Term</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              This agreement remains in effect until terminated by either party
              in accordance with these Terms.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>10.2 Termination by Seller</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              Sellers may terminate their account by providing a 30-day written
              notice to the Marketplace.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>10.3 Termination by Marketplace</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              The Marketplace may suspend or terminate a Seller’s account
              immediately for violations of these Terms or any applicable law.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>10.4 Effects of Termination</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              Upon termination, Sellers must fulfill any outstanding orders.
            </li>
            <li style={listItemStyle}>
              The Marketplace will pay out any remaining funds owed to the Seller
              after deducting applicable fees and charges.
            </li>
          </ul>
        </div>
      </div>

      {/* Section 11 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>11. Limitation of Liability</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>No Indirect Damages:</strong> The Marketplace is not liable
            for any indirect, incidental, or consequential damages.
          </li>
          <li style={listItemStyle}>
            <strong>Maximum Liability:</strong> The Marketplace’s total liability
            shall not exceed the total commissions earned from the Seller in the
            six months preceding the claim.
          </li>
        </ul>
      </div>

      {/* Section 12 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>12. Indemnification</h3>
        <p style={paragraphStyle}>
          Sellers agree to indemnify, defend, and hold harmless the Marketplace
          from any claims, liabilities, damages, or expenses arising from their
          breach of these Terms or violation of any law or the rights of a third
          party.
        </p>
      </div>

      {/* Section 13 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>13. Dispute Resolution</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Negotiation:</strong> Parties agree to attempt to resolve
            disputes informally before initiating legal action.
          </li>
          <li style={listItemStyle}>
            <strong>Governing Law:</strong> These Terms are governed by the laws
            of [Jurisdiction], without regard to conflict of laws principles.
          </li>
          <li style={listItemStyle}>
            <strong>Jurisdiction:</strong> Any legal proceedings must be brought
            in the courts located in [Jurisdiction].
          </li>
        </ul>
      </div>

      {/* Section 14 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>14. Modifications to Terms</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Right to Modify:</strong> The Marketplace reserves the right
            to modify these Terms at any time.
          </li>
          <li style={listItemStyle}>
            <strong>Notice of Changes:</strong> Sellers will be notified of
            significant changes via email or through the Marketplace.
          </li>
          <li style={listItemStyle}>
            <strong>Acceptance of Changes:</strong> Continued use of the
            Marketplace after changes constitutes acceptance of the new Terms.
          </li>
        </ul>
      </div>

      {/* Section 15 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>15. Force Majeure</h3>
        <p style={paragraphStyle}>
          Neither party is liable for failure or delay in performance caused by
          circumstances beyond their reasonable control, including natural
          disasters, war, or governmental actions.
        </p>
      </div>

      {/* Section 16 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>16. Miscellaneous</h3>

        <div style={{ marginLeft: '1rem' }}>
          <h4 style={subHeadingStyle}>16.1 Entire Agreement</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              These Terms constitute the entire agreement between the parties
              regarding the subject matter and supersede all prior agreements.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>16.2 Severability</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              If any provision is found to be unenforceable, the remaining
              provisions will remain in effect.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>16.3 No Waiver</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              Failure to enforce any provision does not constitute a waiver of
              that provision.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>16.4 Assignment</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              Sellers may not assign their rights or obligations without the
              prior written consent of the Marketplace.
            </li>
          </ul>
        </div>
      </div>

      {/* Section 17 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>17. Notices</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Method of Notice:</strong> All notices must be in writing and
            sent to the parties at their registered email addresses.
          </li>
          <li style={listItemStyle}>
            <strong>Receipt of Notice:</strong> Notices are considered received
            24 hours after an email is sent, unless the sending party is notified
            that the email address is invalid.
          </li>
        </ul>
      </div>

      {/* Section 18 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>18. Seller Declarations</h3>

        <div style={{ marginLeft: '1rem' }}>
          <h4 style={subHeadingStyle}>18.1 Confidentiality and Loyalty</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              Sellers acknowledge that all information related to the
              Marketplace’s operations is confidential.
            </li>
            <li style={listItemStyle}>
              Sellers agree not to disclose or misuse any Confidential
              Information during and for 36 months after the termination of this
              agreement.
            </li>
          </ul>

          <h4 style={subHeadingStyle}>18.2 Damages</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}>
              Unauthorized disclosure of Confidential Information may result in
              legal action and a claim for damages, including but not limited to,
              compensation equal to at least two times the base amount defined by
              law.
            </li>
          </ul>
        </div>
      </div>

      {/* Section 19 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>19. Prohibited Activities</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Illegal Products:</strong> Sellers may not list products that
            are illegal, hazardous, or violate any laws.
          </li>
          <li style={listItemStyle}>
            <strong>User Conduct:</strong> Sellers must not engage in fraudulent
            activities, harassment, or any behavior that harms the Marketplace or
            its Users.
          </li>
        </ul>
      </div>

      {/* Section 20 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>20. Compliance with Laws</h3>
        <p style={paragraphStyle}>
          Sellers are responsible for complying with all applicable laws,
          regulations, and standards related to their products and business
          practices.
        </p>
      </div>

      {/* Section 21 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>21. Feedback</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Submission of Feedback:</strong> Sellers may provide feedback
            or suggestions.
          </li>
          <li style={listItemStyle}>
            <strong>Use of Feedback:</strong> The Marketplace may use feedback
            without any obligation to the Seller.
          </li>
        </ul>
      </div>

      {/* Section 22 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>22. Language</h3>
        <p style={paragraphStyle}>
          These Terms are provided in [Language]. In case of any discrepancies,
          the [Language] version shall prevail.
        </p>
      </div>

      {/* Section 23 */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>23. Contact Information</h3>
        <p style={paragraphStyle}>
          For questions or concerns regarding these Terms, please contact us at:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Email:</strong> [Email Address]
          </li>
          <li style={listItemStyle}>
            <strong>Address:</strong> [Physical Address]
          </li>
        </ul>
      </div>

      {/* Acknowledgment */}
      <div style={sectionStyle}>
        <h3 style={headingStyle}>Acknowledgment</h3>
        <p style={paragraphStyle}>
          By registering as a Seller and using the Marketplace, you acknowledge
          that you have read, understood, and agree to be bound by these Terms.
        </p>
      </div>
    </div>
  );
};