import React from 'react';
import { Link } from 'react-router-dom';

const TutorialSection = () => {
  return (
    <section
      id="section3"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      <div className="container">
        <h2 className="display-5">Tutorial</h2>
        <p className="lead">
          Get started with our platform by following our comprehensive tutorial.
        </p>
        <Link to="/tutorial" className="btn btn-info">
          Start Tutorial
        </Link>
      </div>
    </section>
  );
};

export default TutorialSection;