import React, { useEffect, useState } from 'react';
import { db } from "../config/firebase";
import { collection, onSnapshot, query, orderBy, limit } from "firebase/firestore";

export const Admin = () => {
  const [orders, setOrders] = useState([]);
  const [emailLogs, setEmailLogs] = useState([]);
  const [isOrdersCollapsed, setIsOrdersCollapsed] = useState(false);
  const [isEmailLogsCollapsed, setIsEmailLogsCollapsed] = useState(false);

  useEffect(() => {
    const unsubscribeOrders = onSnapshot(
      query(collection(db, 'orders'), orderBy('timestamp', 'desc'), limit(100)),
      (snapshot) => {
        const orderList = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));
        setOrders(orderList);
      },
      (error) => {
        console.error("Error fetching orders: ", error);
      }
    );

    const unsubscribeEmailLogs = onSnapshot(
      query(collection(db, 'email_logs'), orderBy('timestamp', 'desc'), limit(100)),
      (snapshot) => {
        const emailLogList = snapshot.docs.map(doc => doc.data());
        setEmailLogs(emailLogList);
      },
      (error) => {
        console.error("Error fetching email logs: ", error);
      }
    );

    return () => {
      unsubscribeOrders();
      unsubscribeEmailLogs();
    };
  }, []);

  return (
    <div className="container my-4">
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="h3">Order Information</h1>
          <button className="btn btn-outline-primary" onClick={() => setIsOrdersCollapsed(!isOrdersCollapsed)}>
            {isOrdersCollapsed ? 'Show Orders' : 'Hide Orders'}
          </button>
        </div>
        {!isOrdersCollapsed && (
          <table className="table table-striped table-hover mt-3">
            <thead className="thead-dark">
              <tr>
                <th>Order ID</th>
                <th>Customer</th>
                <th>Total Price</th>
                <th>Items</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>{order.id}</td>
                  <td>{order.customer ? `${order.customer.first_name} ${order.customer.last_name}` : 'N/A'}</td>
                  <td>{order.total_price}</td>
                  <td>
                    {order.line_items.map((item, itemIndex) => (
                      <div key={itemIndex}>{item.name} - Quantity: {item.quantity} - Price: {item.price}</div>
                    ))}
                  </td>
                  <td>{order.timestamp ? new Date(order.timestamp.seconds * 1000).toLocaleString() : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
        <h1 className="h3">Email Logs</h1>
        <button className="btn btn-outline-primary" onClick={() => setIsEmailLogsCollapsed(!isEmailLogsCollapsed)}>
            {isEmailLogsCollapsed ? 'Show Email Logs' : 'Hide Email Logs'}
          </button>
        </div>
        {!isEmailLogsCollapsed && (
          <table className="table table-striped table-hover mt-3">
            <thead className="thead-dark">
              <tr>
                <th>Creator Name</th>
                <th>Creator Email</th>
                <th>Line Items</th>
                <th>QR Code</th>
                <th>Shipping Label</th>
                <th>Tracking Link</th>
                <th>Status</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {emailLogs.map((log, index) => (
                <tr key={index}>
                  <td>{log.creatorName}</td>
                  <td>{log.creatorEmail}</td>
                  <td>{log.lineItemsText}</td>
                  <td>
                    <a href={log.QRcodePostNord} target="_blank" rel="noopener noreferrer">View QR Code</a>
                  </td>
                  <td>
                    <a href={log.ShippingLabelPostNord} target="_blank" rel="noopener noreferrer">View Shipping Label</a>
                  </td>
                  <td>
                    <a href={log.trackingLink} target="_blank" rel="noopener noreferrer">Track Shipment</a>
                  </td>
                  <td>{log.status}</td>
                  <td>{new Date(log.timestamp.seconds * 1000).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};