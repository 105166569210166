// src/redux/ordersSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db } from '../../config/firebase';
import { collection, getDocs, onSnapshot } from "firebase/firestore";

// Thunk to fetch orders
export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (_, { getState }) => {
    const { status } = getState().orders;
    if (status === 'succeeded') return; // Prevent re-fetching if already succeeded

    const user = auth.currentUser;
    if (user) {
        const ordersCollectionRef = collection(db, 'users', user.uid, 'orders');
        const ordersSnapshot = await getDocs(ordersCollectionRef);
        const orders = ordersSnapshot.docs.map(doc => {
            const fulfillmentOrder = doc.data().fulfillmentOrder || {}; // Add check here

            return { //ADD FULFILLED
                customer: fulfillmentOrder.destination.first_name,
                customerPhone: fulfillmentOrder.destination.phone,
                customerEmail: fulfillmentOrder.destination.email,
                id: doc.id,
                assigned_location_id: fulfillmentOrder.assigned_location_id || "N/A",
                created_at: fulfillmentOrder.created_at || "N/A",
                fulfill_at: fulfillmentOrder.fulfill_at || "N/A",
                fulfilled: doc.data().fulfilled || false,
                fulfilled: doc.data().fulfilled || false,
                trackingNumber: doc.data().trackingNumber || "N/A",
                trackingLink: doc.data().trackingLink || "N/A", // Add tracking link
                QRcodePostNord: doc.data().QRcodePostNord || "N/A", // Add QR code
                ShippingLabelPostNord: doc.data().ShippingLabelPostNord || "N/A", // Add shipping label
                line_items: (fulfillmentOrder.line_items || []).map(item => ({
                    name: item.name || "N/A",
                    price: item.price || "N/A",
                    quantity: item.quantity || 1,
                    vendor: item.vendor || "N/A",
                })),
            };
        });
        return orders;
    }
    return [];
});

// Function to set up a Firestore listener for orders
export const setupOrdersListener = () => (dispatch) => {
    const user = auth.currentUser;
    if (user) {
        const ordersCollectionRef = collection(db, 'users', user.uid, 'orders');

        // Listen for real-time updates to the orders collection
        const unsubscribe = onSnapshot(ordersCollectionRef, (snapshot) => {
            const orders = snapshot.docs.map(doc => {
                const fulfillmentOrder = doc.data().fulfillmentOrder || {}; // Add check here

                return {
                    customer: fulfillmentOrder.destination.first_name,
                    customerPhone: fulfillmentOrder.destination.phone,
                    customerEmail: fulfillmentOrder.destination.email,                    id: doc.id,
                    assigned_location_id: fulfillmentOrder.assigned_location_id || "N/A",
                    created_at: fulfillmentOrder.created_at || "N/A",
                    fulfill_at: fulfillmentOrder.fulfill_at || "N/A",
                    fulfilled: doc.data().fulfilled || false,
                    trackingNumber: doc.data().trackingNumber || "N/A",
                    trackingLink: doc.data().trackingLink || "N/A", // Add tracking link
                    QRcodePostNord: doc.data().QRcodePostNord || "N/A", // Add QR code
                    ShippingLabelPostNord: doc.data().ShippingLabelPostNord || "N/A", // Add shipping label
                    line_items: (fulfillmentOrder.line_items || []).map(item => ({
                        name: item.name || "N/A",
                        price: item.price || "N/A",
                        quantity: item.quantity || 1,
                        vendor: item.vendor || "N/A",
                    })),
                };
            });

            dispatch(fetchOrders.fulfilled(orders)); // Dispatch the fetched data
        });

        return unsubscribe; // Return the unsubscribe function for cleanup if needed
    }
};

const initialState = {
    orders: [],
    status: 'idle', // idle | loading | succeeded | failed
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                if (state.status === 'succeeded') return;
                state.status = 'loading';
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orders = action.payload; // Set the orders data
            })
            .addCase(fetchOrders.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default ordersSlice.reducer;