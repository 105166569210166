import React, { useState } from 'react';

const DeleteButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div style={{ position: 'relative' }}>
            <button 
                className="btn btn-danger mt-4 mb-4"  // Added margin-bottom
                onClick={handleClick}
            >
                Delete
            </button>

            {/* Custom popup */}
            {isOpen && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Darker overlay
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                    transition: 'opacity 0.3s ease-in-out', // Smooth fade-in effect
                }}>
                    <div style={{
                        backgroundColor: '#fff',
                        padding: '30px',
                        margin: '30px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Smooth shadow
                        transition: 'all 0.3s ease-in-out', // Smooth appearance for the modal
                        transform: 'scale(1)', // Optional: Add slight scaling animation
                    }}>
                        <h3 style={{ marginBottom: '20px' }}>Feature In Progress</h3>
                        <h5 style={{ fontSize: '1rem', color: '#555' }}>
                            We are currently working on this feature
                        </h5>
                        <p style={{ fontSize: '10px', color: '#555' }}>
                            Note: If your product is unaviable you can set the quantity to 0
                        </p>
                        <button 
                            className="btn btn-primary mt-3" 
                            onClick={handleClose}
                            style={{ padding: '8px 20px', borderRadius: '5px' }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeleteButton;