import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Provider, useDispatch } from 'react-redux'; 
import store from './redux/store.js'; 
import './css/allCSS.css';

import { Main } from './pages/main';
import { Login } from './pages/login';
import { Apply } from './pages/apply';
import { Navbar } from './components/navbar';
import { Footer } from './components/footer';
import { Loader } from './components/loaders/loader';
import { Product } from './pages/product';
import { Orders } from './pages/orders';
import { CookieBanner } from './components/cookie';
import { Admin } from './pages/admin';
import { AllProductsPage } from './pages/allProductsPage';
import { EditProductPage } from './pages/editProductPage'; 
import { AddProductPage } from './pages/addProductPage'; 
import { ContentPage } from './pages/contentPage.js'; 

// Import the new mock pages
import { TermsOfServicePage } from './pages/TermsOfServicePage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';

import { fetchUserData, setupUserListener } from './redux/slices/userSlice'; 
import { fetchOrders, setupOrdersListener } from './redux/slices/ordersSlice';
import { fetchProducts, setupProductsListener } from './redux/slices/productsSlice.js';

const auth = getAuth();

function AppContent() {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch(); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setLoading(false);
                dispatch(fetchUserData()); 
                dispatch(setupUserListener());
                dispatch(fetchOrders()); 
                dispatch(setupOrdersListener()); 
                dispatch(fetchProducts()); 
                dispatch(setupProductsListener());
            } else {
                setUser(null);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="App">
            {user && <CookieBanner />}
            {user && <Navbar />}
            
            <Routes>
                <Route 
                    path="/" 
                    element={user ? (
                            <Main />
                    ) : (
                        <Navigate to="/login" />
                    )} 
                />
                <Route 
                    path="/apply" 
                    element={user ? (
                            <Apply />
                    ) : (
                        <Navigate to="/login" />
                    )} 
                />
                <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
                <Route path="/product" element={user ? <Product /> : <Navigate to="/login" />} />
                <Route path="/orders" element={user ? <Orders /> : <Navigate to="/login" />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/allProductsPage" element={<AllProductsPage />} />
                <Route path="/edit-product/:id" element={<EditProductPage />} /> 
                <Route path="/add-products" element={<AddProductPage />} /> 
                <Route path="/content" element={<ContentPage />} /> 

                {/* New Routes for TOS and Privacy Policy */}
                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Routes>
            
            {user && <Footer />}
        </div>
    );
}

function App() {
    return (
        <Provider store={store}>
            <Router>
                <AppContent />
            </Router>
        </Provider>
    );
}

export default App;