// src/components/addProduct/VariantsManager.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductField } from '../../redux/slices/addProductSlice'; // Adjust the path as needed
import { v4 as uuidv4 } from 'uuid'; // To generate unique keys for variants

export const AddVariantsManager = () => {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.addProduct.product); // Access product from addProduct slice
    const [variants, setVariants] = useState([]); // Store the generated variants

    const generateVariants = (options) => {
        // Ensure options exist and are valid
        const validOptions = Array.isArray(options)
            ? options.filter(option => option.name && Array.isArray(option.values) && option.values.length > 0)
            : [];
    
        if (validOptions.length === 0) {
            setVariants([]); // Clear variants if no valid options
            return;
        }
    
        // Generate all combinations of option values
        const cartesianProduct = (arr) => arr.reduce((a, b) => a.flatMap(d => b.map(e => [...d, e])), [[]]);
        const valuesArray = validOptions.map((option) => option.values); // Get all option values
        const combinations = cartesianProduct(valuesArray); // Generate all combinations
    
        const generatedVariants = combinations.map((combination) => {
            const optionValues = combination.map((value, index) => ({
                name: value, // Option value
                optionName: validOptions[index].name, // Corresponding option name
            }));
    
            const title = combination.map((value, index) => `${value}`).join(' / '); // Create the variant title with option names
    
            // Find the existing variant in the product's generatedVariants by matching the title
            const existingVariant = product?.variants?.find(variant => variant.title === title);
    
            return {
                id: existingVariant?.id || uuidv4(),
                title,
                price: existingVariant?.price || product?.price || 0, // Use existing price or default to product price
                inventory_quantity: existingVariant?.inventory_quantity || 0, // Use existing quantity or default to 0
                optionValues, // Include the option name with value
            };
        });
    
        setVariants(generatedVariants); // Update the variants state
    };

    // Handle changes in price and quantity
    const handleVariantChange = (variantId, field, value) => {
        const updatedVariants = variants.map((variant) =>
            variant.id === variantId ? { ...variant, [field]: value } : variant
        );
        setVariants(updatedVariants);

        // Dispatch update to Redux store
        dispatch(updateProductField({ field: 'variants', value: updatedVariants }));
    };

    // UseEffect to generate variants and update Redux store when options change
    useEffect(() => {
        if (product?.options) {
            generateVariants(product.options);
        }
    }, [product?.options]);

    // Update Redux store only after the variants state is updated
    useEffect(() => {
        if (variants.length > 0) {
            dispatch(updateProductField({ field: 'generatedVariants', value: variants }));
            console.log('Updated Redux store with new variants:', variants);
        }
    }, [variants, dispatch]); // No need for product.id

    if (!product) return <div>Loading product data...</div>;

    // Return nothing if there are no variants
    if (variants.length === 0) {
        return null; // No rendering if no variants are generated
    }

    return (
        <div className="container p-4 mb-5 rounded" style={{ backgroundColor: '#f0f0f0'}}>           
            <div className="row g-0">
                {variants.map((variant) => (
                    <div key={variant.id} className="col-sm-8 col-md-6 col-lg-4 p-1">
                        <div className="card shadow-sm p-3">
                            {/* Variant options wrapped in badges */}
                            <div className="mb-0 d-flex flex-wrap">
                                {variant.optionValues.map((option, optionIndex) => (
                                    <span 
                                        key={optionIndex} 
                                        className="badge bg-primary me-1 mb-2"
                                        style={{ fontSize: '0.8rem', padding: '6px' }}
                                    >
                                        {option.optionName}: {option.name}
                                    </span>
                                ))}
                            </div>

                            {/* Editable Price Field */}
                            <label htmlFor={`price-${variant.id}`} className="form-label">Price</label>
                            <input
                                type="number"
                                id={`price-${variant.id}`}
                                className="form-control mb-2"
                                value={variant.price}
                                onChange={(e) => handleVariantChange(variant.id, 'price', parseFloat(e.target.value))}
                            />
                            
                            {/* Editable Quantity Field */}
                            <label htmlFor={`quantity-${variant.id}`} className="form-label">Quantity</label>
                            <input
                                type="number"
                                id={`quantity-${variant.id}`}
                                className="form-control"
                                value={variant.inventory_quantity}
                                onChange={(e) => handleVariantChange(variant.id, 'inventory_quantity', parseInt(e.target.value))}
                                style={{ width: '25%' }}  // This will make the input 25% of its container width
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};