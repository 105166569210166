import React from 'react';

export const SkeletonProductLoader = () => {
    return (
        <>
            <style>
                {`
                /* Skeleton Loader CSS */
                .skeleton-img {
                    background-color: #e0e0e0;
                    animation: skeleton-loading 1.5s infinite;
                    height: 200px;
                    border-radius: 0.25rem 0.25rem 0 0;
                }

                .skeleton-title, .skeleton-text {
                    background-color: #e0e0e0;
                    height: 20px;
                    border-radius: 4px;
                    animation: skeleton-loading 1.5s infinite;
                }

                .skeleton-title {
                    width: 70%;
                    margin-bottom: 10px;
                }

                .skeleton-text {
                    width: 50%;
                }

                /* Skeleton animation */
                @keyframes skeleton-loading {
                    0% {
                        background-color: #e0e0e0;
                    }
                    50% {
                        background-color: #f0f0f0;
                    }
                    100% {
                        background-color: #e0e0e0;
                    }
                }
                `}
            </style>

            <div className="container">
                <div className="row justify-content-center">
                    {/* Display 8 skeleton loaders */}
                    {[...Array(8)].map((_, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4">
                            <div className="card h-100 shadow-sm">
                                <div className="skeleton-img card-img-top"></div>
                                <div className="card-body">
                                    <div className="skeleton-title mb-2"></div>
                                    <div className="skeleton-text"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};