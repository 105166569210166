import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { 
    ref, uploadBytes, getDownloadURL, addDoc, collection, updateDoc, auth, storage, db 
} from "../config/firebase";
import confetti from 'canvas-confetti';

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required").max(500, 'Description is too long'),
    resume: yup.array().required('Photos are required').max(10, 'Cannot upload more than 10 photos'),
    price: yup.number().required("Price is required").positive("Price must be positive"),
    vendor: yup.string().required("Vendor is required"),
    productType: yup.string().required("Product type is required"),
    tags: yup.string().required("Tags are required"),
    sizeVariants: yup.array().of(yup.string()),
    colorVariants: yup.array().of(yup.string())
});

export const Product = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: 'image/*',
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setFiles(previousFiles => [...previousFiles, ...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                id: uuidv4()
            }))]);
        },
    });

    const { register, handleSubmit, formState: { errors }, setValue, trigger, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const removeFile = fileToRemove => {
        setFiles(currentFiles => currentFiles.filter(file => file.id !== fileToRemove.id));
    };

    useEffect(() => {
        setValue('resume', files);
        trigger('resume');
    }, [files, setValue, trigger]);

    const onSubmit = async (data) => {
        setIsLoading(true);
        data = {...data, resume: files};

        const newEntry = {
            name: data.name,
            description: data.description,
            price: data.price,
            timestamp: new Date(),
            googleUserId: auth.currentUser.uid,
            vendor: data.vendor,
            productType: data.productType,
            tags: data.tags,
            variantOptions: variantOptions,
        };

        const docRef = await addDoc(collection(db, "products"), newEntry);
    
        let uploadedFileURLs = [];
        for (let file of data.resume) {
            const storageRef = ref(storage, `products/${docRef.id}/${file.id}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            uploadedFileURLs.push(downloadURL);
        }

        await updateDoc(docRef, { fileURLs: uploadedFileURLs });
    
        confetti({
            particleCount: 500,
            spread: 200,
            origin: { y: 0.6 }
        });

        setFiles([]);
        reset();
        setIsLoading(false);
    };

    const addOption = () => {
        setVariantOptions(prev => [...prev, { colors: [], sizes: [] }]);
    };

    const removeOption = (index) => {
        const newVariantOptions = [...variantOptions];
        newVariantOptions.splice(index, 1);
        setVariantOptions(newVariantOptions);
    };

    const addVariant = (type, optionIndex) => {
        setVariantOptions(prev => {
            const newOptions = [...prev];
            if (type === 'size') {
                newOptions[optionIndex].sizes.push("");
            } else if (type === 'color') {
                newOptions[optionIndex].colors.push("");
            }
            return newOptions;
        });
    };

    const removeSizeVariant = (index, optionIndex) => {
        setVariantOptions(prev => {
            const newOptions = [...prev];
            newOptions[optionIndex].sizes.splice(index, 1);
            return newOptions;
        });
    };

    const removeColorVariant = (index, optionIndex) => {
        setVariantOptions(prev => {
            const newOptions = [...prev];
            newOptions[optionIndex].colors.splice(index, 1);
            return newOptions;
        });
    };

    const setSizeVariantsForOption = (sizes, optionIndex) => {
        setVariantOptions(prev => {
            const newOptions = [...prev];
            newOptions[optionIndex].sizes = sizes;
            return newOptions;
        });
    };

    const setColorVariantsForOption = (colors, optionIndex) => {
        setVariantOptions(prev => {
            const newOptions = [...prev];
            newOptions[optionIndex].colors = colors;
            return newOptions;
        });
    };

    const handleColorChange = (e, optionIndex, colorIndex) => {
        const newColorValue = e.target.value;
        setVariantOptions(prev => {
            const newOptions = [...prev];
            newOptions[optionIndex].colors[colorIndex] = newColorValue;
            return newOptions;
        });
    };

    const handleSizeChange = (e, optionIndex, sizeIndex) => {
        const newSizeValue = e.target.value;
        setVariantOptions(prev => {
            const newOptions = [...prev];
            newOptions[optionIndex].sizes[sizeIndex] = newSizeValue;
            return newOptions;
        });
    };

    return (
        <div className="container mt-5">
            <div className="card shadow-sm p-4">
                <h1 className="text-center mb-4">Add Product</h1>
                <form onSubmit={handleSubmit(onSubmit)} className="product-form">
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Product Name</label>
                        <input
                            {...register("name")}
                            id="name"
                            placeholder="Product Name..."
                            className="form-control"
                        />
                        <p className="text-danger">{errors.name?.message}</p>
                    </div>
    
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                            {...register("description")}
                            id="description"
                            placeholder="Describe your product..."
                            className="form-control"
                        />
                        <p className="text-danger">{errors.description?.message}</p>
                    </div>
    
                    <div className="mb-3">
                        <label htmlFor="fileInput" className="form-label">Product Photos</label>
                        <div {...getRootProps()} className="form-control dropzone p-3" id="fileInput">
                            <input {...getInputProps()} />
                            <p className="mb-2">Drag & drop files here, or <button type="button" onClick={open} className="btn btn-link p-0">browse</button></p>
                            <div className="d-flex flex-wrap">
                                {files.map(file => (
                                    <div key={file.id} className="me-2 mb-2">
                                        <img src={file.preview} alt="preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                        <button type="button" className="btn btn-danger btn-sm mt-1" onClick={() => removeFile(file)}>Remove</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p className="text-danger">{errors.resume?.message}</p>
                    </div>
    
                    <div className="mb-3">
                        <label htmlFor="price" className="form-label">Price</label>
                        <input
                            {...register("price")}
                            id="price"
                            placeholder="Price..."
                            className="form-control"
                        />
                        <p className="text-danger">{errors.price?.message}</p>
                    </div>
    
                    <div className="mb-3">
                        <label htmlFor="vendor" className="form-label">Vendor</label>
                        <input
                            {...register("vendor")}
                            id="vendor"
                            placeholder="Vendor..."
                            className="form-control"
                        />
                        <p className="text-danger">{errors.vendor?.message}</p>
                    </div>
    
                    <div className="mb-3">
                        <label htmlFor="productType" className="form-label">Product Type</label>
                        <input
                            {...register("productType")}
                            id="productType"
                            placeholder="Product Type..."
                            className="form-control"
                        />
                        <p className="text-danger">{errors.productType?.message}</p>
                    </div>
    
                    <div className="mb-3">
                        <label htmlFor="tags" className="form-label">Tags</label>
                        <input
                            {...register("tags")}
                            id="tags"
                            placeholder="Tags (comma separated)..."
                            className="form-control"
                        />
                        <p className="text-danger">{errors.tags?.message}</p>
                    </div>
    
                    <div className="mb-3">
                        <label className="form-label">Variants</label>
                        {variantOptions.map((option, optionIndex) => (
                            <div key={optionIndex} className="border p-3 mb-3 rounded">
                                <h5>Option {optionIndex + 1}</h5>
    
                                <div className="mb-2">
                                    <label className="form-label">Size Variants</label>
                                    {option.sizes.map((size, sizeIndex) => (
                                        <div key={sizeIndex} className="d-flex align-items-center mb-2">
                                            <input
                                                value={size}
                                                onChange={(e) => handleSizeChange(e, optionIndex, sizeIndex)}
                                                placeholder="Size"
                                                className="form-control me-2"
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm"
                                                onClick={() => removeSizeVariant(sizeIndex, optionIndex)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => addVariant('size', optionIndex)}
                                    >
                                        Add Size
                                    </button>
                                </div>
    
                                <div className="mb-2">
                                    <label className="form-label">Color Variants</label>
                                    {option.colors.map((color, colorIndex) => (
                                        <div key={colorIndex} className="d-flex align-items-center mb-2">
                                            <input
                                                value={color}
                                                onChange={(e) => handleColorChange(e, optionIndex, colorIndex)}
                                                placeholder="Color"
                                                className="form-control me-2"
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm"
                                                onClick={() => removeColorVariant(colorIndex, optionIndex)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => addVariant('color', optionIndex)}
                                    >
                                        Add Color
                                    </button>
                                </div>
    
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm mt-2"
                                    onClick={() => removeOption(optionIndex)}
                                >
                                    Remove Option
                                </button>
                            </div>
                        ))}
                        <button type="button" className="btn btn-primary" onClick={addOption}>
                            Add Option
                        </button>
                    </div>
    
                    <div className="d-grid gap-2">
                        <button
                            type="submit"
                            className="btn btn-success"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                "Add Product"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};