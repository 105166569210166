// src/pages/AddProductPage.js

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProductToFirestore,
  updateProductField,
  resetProductState,
  setError,
  clearError,
} from '../redux/slices/addProductSlice';
import { useForm } from 'react-hook-form';
import { SkeletonProductLoader } from '../components/loaders/SkeletonProductLoader.js';
import { AddProductImageManager } from '../components/addProduct/addProductImageManager.js';
import { AddOptionsManager } from '../components/addProduct/addOptionsManager.js';
import { AddVariantsManager } from '../components/addProduct/addVariantsManager.js';
import { PresetOptionsCard } from '../components/addProduct/PresetOptionsCard.js';

import { BsImage } from 'react-icons/bs'; // Import the image icon
import { SuccessPopup } from '../components/addProduct/SuccessAddPopup.js'; // Import the SuccessPopup component

// Import React Quill and Quill for rich text editing
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles

export const AddProductPage = () => {
  const dispatch = useDispatch();
  const addProductState = useSelector((state) => state.addProduct);
  const { product, status, error: reduxError } = addProductState;

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: product,
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State to control the popup visibility

  const hasDefaultVariant = product?.isDefault;

  // Create a ref for ReactQuill
  const quillRef = useRef(null);

  // Define the inline CSS styles for the Quill editor
  const quillEditorStyles = `
    .product-description-editor .ql-editor p {
      margin-bottom: 1rem; /* Adjust as needed */
      margin-top: 0; /* Optional */
    }
  `;

  useEffect(() => {
    // Set form values when product state changes
    if (product) {
      setValue('title', product.title);
      setValue('price', product.variants?.[0]?.price || '');
      setValue('description', product.description);

      // Set the initial quantity from the first variant
      const totalQuantity = product.variants?.[0]?.inventory_quantity || 0;
      setQuantity(totalQuantity);

      // Set the initial price from the first variant
      const variantPrice = product.variants?.[0]?.price || '';
      setPrice(variantPrice);
    }
  }, [product, setValue]);

  // Inject the CSS styles into the document head
  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.type = 'text/css';
    styleTag.appendChild(document.createTextNode(quillEditorStyles));
    document.head.appendChild(styleTag);

    // Cleanup function to remove the style tag on unmount
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  // Add custom Shift+Enter binding to Quill editor
  useEffect(() => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor();

      // Remove existing Shift+Enter binding if any
      editor.keyboard.bindings[13] = editor.keyboard.bindings[13].filter(
        (binding) => !binding.shiftKey
      );

      // Add custom Shift+Enter binding
      editor.keyboard.addBinding(
        {
          key: 13, // Enter key
          shiftKey: true,
        },
        function (range, context) {
          // Insert a line break
          editor.insertText(range.index, '\n', Quill.sources.USER);
          editor.setSelection(range.index + 1, Quill.sources.SILENT);
        }
      );
    }
  }, [quillRef]);

  // Function to handle local state updates for product fields (Redux state)
  const handleFieldChange = (field, value) => {
    dispatch(updateProductField({ field, value }));
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);

    // Update the first variant's quantity
    let updatedVariants = [...(product?.variants || [])];
    if (updatedVariants.length === 0) {
      updatedVariants.push({});
    }
    updatedVariants[0] = {
      ...updatedVariants[0],
      inventory_quantity: newQuantity,
    };

    // Dispatch the updated variants to Redux
    dispatch(updateProductField({ field: 'variants', value: updatedVariants }));
  };

  const handlePriceChange = (newPrice) => {
    setPrice(newPrice);

    // Update the first variant's price
    let updatedVariants = [...(product?.variants || [])];
    if (updatedVariants.length === 0) {
      updatedVariants.push({});
    }
    updatedVariants[0] = {
      ...updatedVariants[0],
      price: newPrice,
    };

    // Dispatch the updated variants to Redux
    dispatch(updateProductField({ field: 'variants', value: updatedVariants }));
  };

  const onSubmit = (data) => {
    // Clear previous messages
    dispatch(clearError());

    // Validate required fields
    const { title, description, images } = product || {};
    if (!title || !description || !images || images.length === 0) {
      dispatch(
        setError(
          'Please fill in all required fields: title, description, and at least one image.'
        )
      );
      return;
    }

    // Proceed to add product
    dispatch(addProductToFirestore())
      .unwrap()
      .then(() => {
        console.log('Product successfully added to Firestore.');
        setShowSuccessPopup(true); // Show the success popup
        // Optionally reset the product state
        dispatch(resetProductState());
      })
      .catch((error) => {
        console.error('Error adding product:', error);
        dispatch(setError(error.message || 'Error adding product. Please try again.'));
      });
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < (product?.images?.length || 0) - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
    window.location.href = '/'; // Redirect to the main page or desired location
  };

  // Define the modules for React Quill
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
      matchVisual: false, // Disable the matchVisual option
    },
    // No need to add keyboard bindings here since we added them in useEffect
  };

  // Ensure product is defined before rendering the component
  if (!product) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          Product data is not available.
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      {/* Include the SuccessPopup component */}
      <SuccessPopup show={showSuccessPopup} onClose={handleClosePopup} />

      {/* Header: Add Product and Save button */}
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1>Add Product</h1>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit(onSubmit)}
              disabled={status === 'loading'}
            >
              {status === 'loading' ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        {/* Product Image Carousel and PresetOptionsCard */}
        <div className="col-md-4 d-flex flex-column">
          <div
            className="card mb-4 flex-grow-1"
            style={{ borderRadius: '10px', overflow: 'hidden', position: 'relative' }}
          >
            {/* Check if product images or base64 exists, if not show the placeholder */}
            {product.images && product.images.length > 0 ? (
              <div id="productCarousel" className="carousel slide" data-bs-touch="true">
                <div className="carousel-inner">
                  {product.images.map((image, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                      style={{ position: 'relative' }}
                    >
                      {image.base64 || image.src ? (
                        <img
                          src={image.base64 || image.src}
                          className="d-block w-100"
                          alt={`Product image ${index + 1}`}
                          style={{ objectFit: 'cover', height: '300px', zIndex: '-1' }}
                        />
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            height: '300px',
                          }}
                        ></div>
                      )}
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          color: 'white',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          zIndex: 1,
                        }}
                      >
                        {index + 1} / {product.images.length}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  onClick={handlePrev}
                  disabled={currentIndex === 0}
                >
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  onClick={handleNext}
                  disabled={currentIndex === product.images.length - 1}
                >
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            ) : (
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '0px 10px 0px 0px',
                  height: '300px',
                  backgroundColor: '#f0f0f0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BsImage size={80} color="#6c757d" />
              </div>
            )}

            <div className="card-body">
              <h5 className="card-title">{product.title || 'Untitled'}</h5>
              <div
                className="card-text"
                dangerouslySetInnerHTML={{
                  __html: product.description || 'No description available',
                }}
              ></div>
            </div>
          </div>

          {/* Place the PresetOptionsCard here */}
        </div>

        {/* Product Form */}
        <div className="col-md-8 d-flex flex-column">
          {/* Display Error Messages */}
          {reduxError && (
            <div className="alert alert-danger" role="alert">
              {reduxError}
            </div>
          )}

          <form className="d-flex flex-column flex-grow-1">
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Product Name
              </label>
              <input
                {...register('title')}
                id="title"
                className="form-control"
                onChange={(e) => handleFieldChange('title', e.target.value)}
              />
            </div>

            <div className="mb-3 d-flex align-items-center">
              <div className="me-2" style={{ flex: '1' }}>
                <label htmlFor="price" className="form-label">
                  Price
                </label>
                <input
                  type="text"
                  value={hasDefaultVariant ? price : ''}
                  id="price"
                  className="form-control"
                  onChange={(e) => handlePriceChange(e.target.value)}
                  disabled={!hasDefaultVariant}
                  placeholder={
                    !hasDefaultVariant ? 'Edit Price and Quantity from the Options' : ''
                  }
                />
              </div>
              <div style={{ width: '100px' }}>
                <label htmlFor="quantity" className="form-label">
                  Quantity
                </label>
                <input
                  type="text"
                  value={hasDefaultVariant ? quantity : ''}
                  id="quantity"
                  className="form-control"
                  style={{ width: '100%' }}
                  onChange={(e) => handleQuantityChange(parseInt(e.target.value, 10))}
                  disabled={!hasDefaultVariant}
                />
              </div>
            </div>

            <div
              className="mb-4"
              style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
            >
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <div style={{ flexGrow: 1 }}>
                <ReactQuill
                  ref={quillRef}
                  className="product-description-editor"
                  value={product.description || ''}
                  onChange={(value) => handleFieldChange('description', value)}
                  modules={modules}
                  formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                  style={{
                    flexGrow: 1,
                    borderRadius: '10px',
                    height: '99%',
                    paddingBottom: '40px',
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <AddProductImageManager />
      <AddOptionsManager />
      <AddVariantsManager />
    </div>
  );
};