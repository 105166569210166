import React from 'react';
import { Link } from 'react-router-dom';

const PaymentsSection = () => {
  return (
    <section
      id="section8"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      <div className="container">
        <h2 className="display-5">Payments</h2>
        <p className="lead">
          Manage your payments and financial transactions.
        </p>
        <Link to="/payments" className="btn btn-success">Manage Payments</Link>
      </div>
    </section>
  );
};

export default PaymentsSection;