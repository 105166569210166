import { signInWithPopup, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { auth, provider, db } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import '../css/Login.css';
import logo from '../media/cay_logo.png';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FcGoogle } from 'react-icons/fc'; // Import Google icon

export const Login = () => {
    const navigate = useNavigate();

    const signInWithGoogle = async () => {
        try {
            if (isMobile) {
                // On mobile devices, use signInWithRedirect
                await signInWithRedirect(auth, provider);
                
            } else {
                // On desktops or laptops, use signInWithPopup
                const result = await signInWithPopup(auth, provider);
                await handleUser(result.user);
            }
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const handleUser = async (user) => {
        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(userRef);

            if (!docSnap.exists()) {
                await setDoc(userRef, {
                    uid: user.uid,
                    gname: user.displayName,
                    email: user.email,
                    verified: false,
                });
            }

            navigate("/");
        }
    };

    useEffect(() => {
        // Check if we're coming back from a redirect on mobile
        const checkRedirect = async () => {
            try {
                const result = await getRedirectResult(auth);
                if (result) {
                    await handleUser(result.user);
                }
            } catch (error) {
                console.error("Error getting redirect result:", error);
            }
        };

        if (isMobile) {
            checkRedirect();
        }
    }, []);

    return (
        <div className="login-container-login">
            <div className="logo-container-login">
                <img src={logo} alt="Company Logo" />
            </div>

            <h1>This app is under construction.</h1>
            <h3>v1.1</h3>
            
            <button className="google-signin-btn" onClick={signInWithGoogle}>
                <FcGoogle size={24} style={{ marginRight: '8px' }} /> {/* Use Google icon */}
                Sign in with Google
            </button>
        </div>
    );
};