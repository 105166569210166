import { useSelector } from 'react-redux';
import { useState } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from '../config/firebase';

export const Orders = () => {
    const orders = useSelector(state => state.orders.orders);
    const [filter, setFilter] = useState('all');
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleCheckboxChange = (orderId) => {
        setSelectedOrders(prev => 
            prev.includes(orderId) ? prev.filter(id => id !== orderId) : [...prev, orderId]
        );
    };

    const handleSave = async () => {
        const user = auth.currentUser;
        if (!user) return;

        for (const orderId of selectedOrders) {
            const orderDocRef = doc(db, 'users', user.uid, 'orders', orderId);
            await updateDoc(orderDocRef, { fulfilled: filter !== 'fulfilled' });
        }

        setSelectedOrders([]); // Clear selections after save
    };

    const highlightText = (text, query) => {
        if (!query) return text;
        const parts = text.split(new RegExp(`(${query})`, 'gi'));
        return parts.map((part, index) => 
            part.toLowerCase() === query.toLowerCase() ? (
                <mark 
                    key={index} 
                    style={{ 
                        backgroundColor: 'lightgrey', 
                        borderRadius: '4px', 
                        padding: '0px 1px', // Minimized padding
                        lineHeight: '1.2' // Reduces vertical space
                    }}
                >
                    {part}
                </mark>
            ) : (
                part
            )
        );
    };

    const filteredOrders = orders
    .filter(order => {
        if (filter === 'all') return true;
        return filter === 'unfulfilled' ? !order.fulfilled : order.fulfilled;
    })
    .filter(order => {
        const customerMatch = order.customer?.toLowerCase().includes(searchQuery.toLowerCase());
        const itemMatch = order.line_items.some(item => 
            item.name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        const trackingMatch = order.trackingNumber?.toLowerCase().includes(searchQuery.toLowerCase()); // New logic to include tracking number
        return customerMatch || itemMatch || trackingMatch;
    })
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const renderOrderRow = (order) => (
        <tr key={order.id} style={{ backgroundColor: !order.fulfilled ? '#ffebee' : 'white' }}>
            <td>
                <input 
                    type="checkbox" 
                    disabled={filter === 'all' && order.fulfilled}
                    checked={selectedOrders.includes(order.id) || (order.fulfilled && filter === 'all')}
                    onChange={() => handleCheckboxChange(order.id)} 
                />
            </td>
            <td style={{ width: '30%' }}>
                {order.line_items.map((item, index) => (
                    <div key={index}>
                        {highlightText(item.name, searchQuery)} - {item.quantity} pcs
                        {index < order.line_items.length - 1 && <hr style={{ margin: '8px 0' }} />}
                    </div>
                ))}
                <span 
                    className="tooltip-hover"
                    data-tooltip="Tracking Number | Found in order email | Search for last four"
                    style={{
                        fontSize: '12px',
                        color: '#6c757d', // Subtle gray color for the tracking number
                        textDecoration: 'underline', // Makes it look like a tooltip
                        cursor: 'help'
                    }}
                >
                    {highlightText(order.trackingNumber || "N/A", searchQuery)}
                </span>
            </td>
            <td>{new Date(order.created_at).toLocaleDateString()}</td>
            <td>
                <span 
                    className="tooltip-hover"
                    data-tooltip={`Press to copy | Phone: ${order.customerPhone || 'N/A'} | Email: ${order.customerEmail || 'N/A'}`}
                    onClick={() => {
                        const contactInfo = `Phone: ${order.customerPhone || ''}, Email: ${order.customerEmail || ''}`;
                        navigator.clipboard.writeText(contactInfo);
                    }}
                >
                    {highlightText(order.customer || "N/A", searchQuery)}
                </span>
            </td>
            <td>{order.total || "N/A"}</td>
            <td>
                <a href={order.trackingLink} target="_blank" rel="noopener noreferrer">Tracking</a>
            </td>
            <td>
                <a href={order.QRcodePostNord} target="_blank" rel="noopener noreferrer">QR Code</a>
            </td>
            <td>
                <a href={order.ShippingLabelPostNord} target="_blank" rel="noopener noreferrer">Shipping Label</a>
            </td>
        </tr>
    );

    return (
        <div className="container mt-4" style={{ backgroundColor: "white" }}>
        <style>
            {`

    .tooltip-hover {
        position: relative;
        cursor: pointer;
        text-decoration: underline;
    }

    .tooltip-hover:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        left: 0;
        bottom: 100%;
        white-space: nowrap;
        background-color: #333;
        color: #fff;
        padding: 5px;
        border-radius: 4px;
        z-index: 10;
        font-size: 12px;
        opacity: 1;
    }

    .tooltip-hover:hover::before {
        content: "";
        position: absolute;
        left: 10px;
        bottom: 100%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #333 transparent;
    }

            `}
        </style>
            {/* Blue Header Section */}
            <div className="bg-primary text-white p-4 rounded mb-4">
                <h1 className="mb-3">Your Orders</h1>
                <hr className="bg-light" />
                <p className="mb-2">
                    • Unfulfilled orders should be shipped to customers as soon as possible.
                </p>
                <p className="mb-2">
                    • After shipping an order, mark it as fulfilled to keep your orders organized.
                </p>
                <p>
                    • Use the buttons below to filter orders by their status.
                </p>
            </div>
    
            {/* Filter buttons and Save button */}
            <div>{orders.totalOrders}</div>
            <div className="d-flex justify-content-between align-items-center mb-0">
                <div className="btn-group" role="group">
                    <button
                        type="button"
                        className={`btn ${filter === 'all' ? 'btn-primary' : 'btn-outline-secondary'}`}
                        onClick={() => setFilter('all')}
                        style={{ 
                            borderColor: '#ced4da', 
                            borderBottom: 'none', 
                            borderBottomLeftRadius: '0', 
                            borderBottomRightRadius: '0',
                            padding: '7px 14px',
                            fontSize: '12px',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        All
                    </button>
                    <button
                        type="button"
                        className={`btn ${filter === 'unfulfilled' ? 'btn-primary' : 'btn-outline-secondary'}`}
                        onClick={() => setFilter('unfulfilled')}
                        style={{ 
                            borderColor: '#ced4da', 
                            borderBottom: 'none', 
                            borderBottomLeftRadius: '0', 
                            borderBottomRightRadius: '0',
                            padding: '7px 14px',
                            fontSize: '12px',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        Unfulfilled
                    </button>
                    <button
                        type="button"
                        className={`btn ${filter === 'fulfilled' ? 'btn-primary' : 'btn-outline-secondary'}`}
                        onClick={() => setFilter('fulfilled')}
                        style={{ 
                            borderColor: '#ced4da', 
                            borderBottom: 'none', 
                            borderBottomLeftRadius: '0', 
                            borderBottomRightRadius: '0',
                            padding: '7px 14px',
                            fontSize: '12px',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        Fulfilled
                    </button>
                </div>
                <button 
                    type="button" 
                    className="btn btn-primary" 
                    disabled={selectedOrders.length === 0} 
                    onClick={handleSave}
                    style={{ 
                        borderColor: '#ced4da', 
                        borderBottom: 'none', 
                        borderBottomLeftRadius: '0', 
                        borderBottomRightRadius: '0',
                        padding: '7px 14px',
                        fontSize: '12px',
                    }}
                >
                    {filter === 'fulfilled' ? 'Unfulfill' : 'Fulfill'}
                </button>
            </div>
    
            {/* Search Field */}
            <div className="mb-0">
                <input 
                    type="text" 
                    className="form-control form-control-sm" 
                    placeholder="Search orders..." 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} 
                    style={{
                        width: '100%', 
                        padding: '4px 12px', 
                        borderRadius: '0px 0px 0px 0px', // Sets left top border radius to zero
                        border: '1px solid #ced4da', 
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        transition: 'border-color 0.3s',
                    }}
                    onFocus={(e) => e.target.style.borderColor = '#007bf'}
                    onBlur={(e) => e.target.style.borderColor = '#ced4da'}
                />
            </div>
    
            {/* Table inside a rounded container */}
            <div 
                className="mb-5 p-0 shadow-sm" 
                style={{ 
                    border: '1px solid #ced4da',  // Match the border color with search and buttons
                    borderTopLeftRadius: '0',  // No top rounding
                    borderTopRightRadius: '0',  // No top rounding
                    borderBottomLeftRadius: '8px',  // Rounded bottom
                    borderBottomRightRadius: '8px',  // Rounded bottom
                }}
            >
                <div className="table-responsive" style={{ height: '400px', overflowY: 'auto', overflowX: 'auto' }}>
                    <table className="table table-hover table-striped" style={{ minWidth: '1000px' }}>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col"></th> {/* Removed "Select" */}
                                <th scope="col">Order and Tracking Number</th>
                                <th scope="col">Date</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Total</th>
                                <th scope="col">Tracking Link</th>
                                <th scope="col">QR Code</th>
                                <th scope="col">Shipping Label</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders.length === 0 ? (
                                <tr>
                                    <td colSpan="8" className="text-center">No orders found.</td>
                                </tr>
                            ) : (
                                filteredOrders.map(renderOrderRow)
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};