import React from 'react';
import { Link } from 'react-router-dom';

const ReturnsSection = () => {
  return (
    <section
      id="section9"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      <div className="container">
        <h2 className="display-5">Returns</h2>
        <p className="lead">
          Handle returns and exchanges smoothly.
        </p>
        <Link to="/returns" className="btn btn-danger">Handle Returns</Link>
      </div>
    </section>
  );
};

export default ReturnsSection;