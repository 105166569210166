import React from 'react';
import { Link } from 'react-router-dom';

const EditProductsSection = () => {
  return (
    <section
      id="section5"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      <div className="container">
        <h2 className="display-5">Edit Products</h2>
        <p className="lead">
          Update and manage your existing product listings.
        </p>
        <Link to="/allProductsPage" className="btn btn-secondary">
          Edit Products
        </Link>
      </div>
    </section>
  );
};

export default EditProductsSection;