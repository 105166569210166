import { BsCashCoin, BsBoxSeam, BsArrowDownUp, BsFileEarmarkCheck } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

const Dashboard = () => {

    const {
        creatorName,
        nextPaymentAmount,
        nextPaymentDate,
        ordersThisMonth,
        pendingReturns,
        returnsThisMonth,
        verified,
        verificationDate,
        applications,
        status,
        fulfilledOrders,
        unfulfilledOrders,
        totalOrders
    } = useSelector((state) => state.user);

    const isLoading = status === 'loading';

    const getStatusText = () => {
        if (isLoading) return null;
        if (verified) return "Creator";
        if (!verified && applications) return "Pending";
        return "Visitor";
    };

    const getStatusColor = () => {
        if (isLoading) return '';
        if (verified) return 'text-success';
        if (!verified && applications) return 'text-warning';
        return 'text-danger';
    };

    return (
        <section id="Dashboard" className="mb-5 p-3 rounded shadow-sm" style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}>
            <div className="container">
            <h2 className="display-5">Dashboard</h2>
            <h4 className="text-muted">{creatorName}</h4>
                <div className="row text-center">
                    <div className="col-lg-3 col-md-6 mb-4">
                        <Link to="/payments" className="text-decoration-none">
                            <div className="card bg-light shadow-sm h-100">
                                <div className="card-body">
                                    <BsCashCoin className="mb-3" size={40} color="#28a745" />
                                    <h5 className="card-title">Next Payment</h5>
                                    <p className="card-text fs-4">
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-6 rounded"></span>
                                            </div>
                                        ) : (
                                            `${nextPaymentAmount ? nextPaymentAmount + " kr" : ""}`
                                        )}
                                    </p>
                                    <small className="text-muted">
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-4 rounded"></span>
                                            </div>
                                        ) : (
                                            `Expected on: ${nextPaymentDate}`
                                        )}
                                    </small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <Link to="/orders" className="text-decoration-none">
                            <div className="card bg-light shadow-sm h-100">
                                <div className="card-body">
                                    <BsBoxSeam className="mb-3" size={40} color="#007bff" />
                                    <h5 className="card-title">Unfulfilled Orders</h5>
                                    <p className="card-text fs-4">
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-6 rounded"></span>
                                            </div>
                                        ) : (
                                            unfulfilledOrders
                                        )}
                                    </p>
                                    <small className="text-muted">
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-4 rounded"></span>
                                            </div>
                                        ) : (
                                            `All time orders: ${totalOrders}`
                                        )}
                                    </small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <Link to="/returns" className="text-decoration-none">
                            <div className="card bg-light shadow-sm h-100">
                                <div className="card-body">
                                    <BsArrowDownUp className="mb-3" size={40} color="#dc3545" />
                                    <h5 className="card-title">Pending Returns</h5>
                                    <p className="card-text fs-4">
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-6 rounded"></span>
                                            </div>
                                        ) : (
                                            pendingReturns
                                        )}
                                    </p>
                                    <small className="text-muted">
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-4 rounded"></span>
                                            </div>
                                        ) : (
                                            `Returns this month: ${returnsThisMonth}`
                                        )}
                                    </small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <Link to="/apply" className="text-decoration-none">
                            <div className="card bg-light shadow-sm h-100">
                                <div className="card-body">
                                    <BsFileEarmarkCheck className="mb-3" size={40} color="#28a745" />
                                    <h5 className="card-title">Account Status</h5>
                                    <p className={`card-text fs-4 ${getStatusColor()}`}>
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-6 rounded"></span>
                                            </div>
                                        ) : (
                                            getStatusText()
                                        )}
                                    </p>
                                    <small className="text-muted">
                                        {isLoading ? (
                                            <div className="placeholder-glow rounded-placeholder">
                                                <span className="placeholder col-4 rounded"></span>
                                            </div>
                                        ) : (
                                            verified ? `Account verified on: ${verificationDate}` : ''
                                        )}
                                    </small>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;