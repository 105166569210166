// src/components/ContentPage.js

import React from 'react';
import { ContentManager } from '../components/ContentManager'; // Adjust the path as needed
import { BsSun, BsMic, BsCameraVideo, BsCloudUpload } from 'react-icons/bs';

export const ContentPage = () => {
  return (
    <div className="container-fluid py-4" style={{ backgroundColor: '#f9f9f9' }}>
      {/* Step-by-Step Tutorial */}
      <section className="mb-5">
        <div className="container">
          {/* New Heading in a Bigger Card */}
          <div className="card mb-4 shadow-sm bg-primary text-white p-4">
            <div className="card-body text-center">
              <h2 className="card-title mb-3">
                Create Together. Grow Together.
              </h2>
              <p className="card-text lead mb-4">
                Your amazing videos helps the <strong>Collective</strong> grow. We use the content for marketing and social media!
              </p>
              <a href="#upload-section" className="btn btn-light btn-lg">
                Upload Now
              </a>
            </div>
          </div>

          <div className="row">
            {/* Step 1 */}
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body">
                  <BsSun className="mb-3" size={40} color="#ffc107" />
                  <h5 className="card-title">1. Set Up Good Lighting</h5>
                  <p className="card-text text-dark">
                    Ensure your workspace is well-lit. Natural light works best!
                  </p>
                </div>
              </div>
            </div>
            {/* Step 2 */}
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body">
                  <BsMic className="mb-3" size={40} color="#007bff" />
                  <h5 className="card-title">2. Record Clear Audio</h5>
                  <p className="card-text text-dark">
                    Use a quiet space or a microphone to capture clear sound.
                  </p>
                </div>
              </div>
            </div>
            {/* Step 3 */}
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body">
                  <BsCameraVideo className="mb-3" size={40} color="#28a745" />
                  <h5 className="card-title">3. Capture Your Creative Process</h5>
                  <p className="card-text text-dark">
                    Show your art in action. Include a captivating "hook"!
                  </p>
                </div>
              </div>
            </div>
            {/* Step 4 */}
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body">
                  <BsCloudUpload className="mb-3" size={40} color="#17a2b8" />
                  <h5 className="card-title">4. Upload Your Video</h5>
                  <p className="card-text text-dark">
                    Send us your raw footage. We'll take care of the rest!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Example Videos */}
      <section className="mb-5">
        <div className="container">
          <h2 className="mb-4">Inspiration Corner</h2>
          <p>
            Check out these popular videos! Try their <strong>hooks</strong> or create your own unique twist.
          </p>
          <div className="row">
            {/* Video 1 */}
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="ratio ratio-1x1">
                  <iframe
                    src="https://www.youtube.com/embed/pl5ftVVlY7I"
                    allowFullScreen
                    title="Pottery Creation"
                  ></iframe>
                </div>
                <div className="card-body">
                  <p className="card-text text-dark">
                    <strong>Pottery Creation</strong>: Make a to-go pottery cup with me!
                  </p>
                  <p className="card-text text-dark">
                    <strong>Hook:</strong> Begin with the end result and use big statements like "taking over the world."
                  </p>
                </div>
              </div>
            </div>
            {/* Video 2 */}
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="ratio ratio-1x1">
                  <iframe
                    src="https://www.youtube.com/embed/QGWXGhij-1w"
                    allowFullScreen
                    title="Art Progression"
                  ></iframe>
                </div>
                <div className="card-body">
                  <p className="card-text text-dark">
                    <strong>Art Progression</strong>: What do you think will be added next?
                  </p>
                  <p className="card-text text-dark">
                    <strong>Hook:</strong> Is she destroying her painting?!
                  </p>
                </div>
              </div>
            </div>
            {/* Video 3 */}
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="ratio ratio-1x1">
                  <iframe
                    src="https://www.youtube.com/embed/NI-LL-OoOsg"
                    allowFullScreen
                    title="Fashion Creation"
                  ></iframe>
                </div>
                <div className="card-body">
                  <p className="card-text text-dark">
                    <strong>Fashion Creation</strong>: Making a corset floral chiffon long dress.
                  </p>
                  <p className="card-text text-dark">
                    <strong>Hook:</strong> What is she going to pack?!
                  </p>
                </div>
              </div>
            </div>
            {/* Video 4 */}
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="ratio ratio-1x1">
                  <iframe
                    src="https://www.youtube.com/embed/jCsPYFFlb2U"
                    allowFullScreen
                    title="Rug Making"
                  ></iframe>
                </div>
                <div className="card-body">
                  <p className="card-text text-dark">
                    <strong>Rug Making</strong>: Sandstorm rug making process.
                  </p>
                  <p className="card-text text-dark">
                    <strong>Hook:</strong> Look at all those threads in the bags!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Raw Content Explanation */}
      <section className="mb-5">
        <div className="container">
          <div className="alert alert-secondary bg-primary" role="alert">
            <h4 className="alert-heading text-light">No Need to Edit!</h4>
            <p className="text-light">
              We love working with raw, unedited videos. The better the quality of your original footage, the better the final result. Just focus on capturing your creative process and a great "hook"!
            </p>
            <hr />
            <p className="mb-0 text-light">
              Note: You can send us edited videos if you want. Also, we receive a lot of content and might not use everything, but we appreciate every submission! If you see that we have posted you original content, you are free to use it!
            </p>
          </div>
        </div>
      </section>

      {/* Upload Section */}
      <section id="upload-section">
        <div className="container">
          <h2 className="">Upload Your Content</h2>
          <p className="">We will tag you 😄 and we hope that you tag Cay Collective</p>          
          <ContentManager />
        </div>
      </section>
    </div>
  );
};