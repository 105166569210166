// src/components/addProduct/ProductImageManager.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductField } from '../../redux/slices/addProductSlice'; // Adjust the path as needed
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import ImageLoader from '../loaders/imageLoader';

const ItemTypes = {
    IMAGE: 'image',
};

const DraggableImage = ({ image, index, moveImage, handleDelete }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (image.src || image.base64) {
            setIsLoading(false); // Image data is available, stop loading
        } else {
            setIsLoading(true); // No image data, show loader
        }
    }, [image.src, image.base64]);

    const [, ref] = useDrag({
        type: ItemTypes.IMAGE,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemTypes.IMAGE,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveImage(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <div ref={(node) => ref(drop(node))} className="col-6 col-md-2 position-relative mb-3">
            <div
                className="card shadow-sm"
                style={{
                    width: '100%',
                    aspectRatio: '1/1',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                {isLoading ? (
                    <ImageLoader />
                ) : (
                    <img
                        src={image.src || image.base64}
                        alt={`Product image ${index + 1}`}
                        className="d-block w-100"
                        style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                )}
                <div
                    className="badge bg-dark position-absolute d-flex justify-content-center align-items-center"
                    style={{
                        top: '5px',
                        right: '5px',
                        width: '25px',
                        height: '25px',
                        borderRadius: '20%',
                        fontSize: '0.8rem',
                    }}
                >
                    {index + 1}
                </div>
                <button
                    type="button"
                    className="btn btn-danger btn-sm position-absolute d-flex justify-content-center align-items-center"
                    style={{
                        top: '5px',
                        left: '5px',
                        width: '25px',
                        height: '25px',
                        borderRadius: '20%',
                        padding: '0',
                        fontSize: '0.7rem',
                    }}
                    onClick={() => handleDelete(index)}
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export const AddProductImageManager = () => {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.addProduct.product);

    const [images, setImages] = useState(product?.images || []);
    const [isDragging, setIsDragging] = useState(false);

    // Sync the local images state with Redux product images when product changes
    useEffect(() => {
        setImages(product?.images || []);
    }, [product]);

    // Whenever images are updated, dispatch the updated images to Redux
    useEffect(() => {
        dispatch(updateProductField({ field: 'images', value: images }));
    }, [images, dispatch]);

    const handleDelete = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };

    const handleAddNewImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = {
                    base64: reader.result,
                    fileName: file.name,
                    fileType: file.type,
                };
                setImages((prev) => [...prev, newImage]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = {
                    base64: reader.result,
                    fileName: file.name,
                    fileType: file.type,
                };
                setImages((prev) => [...prev, newImage]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const moveImage = (fromIndex, toIndex) => {
        const updatedImages = [...images];
        const [movedImage] = updatedImages.splice(fromIndex, 1);
        updatedImages.splice(toIndex, 0, movedImage);
        setImages(updatedImages);
    };

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div className="container p-4 mb-4 rounded" style={{ backgroundColor: '#f0f0f0' }}>
                <div className="row g-3">
                    {images.map((image, index) => (
                        <DraggableImage
                            key={index}
                            image={image}
                            index={index}
                            moveImage={moveImage}
                            handleDelete={handleDelete}
                        />
                    ))}

                    {/* Placeholder for adding new images with drag-and-drop */}
                    <div className="col-6 col-md-2 mb-0">
                        <label
                            htmlFor="addImage"
                            className={`card d-flex justify-content-center align-items-center shadow-sm ${
                                isDragging ? 'border border-primary' : ''
                            }`}
                            style={{
                                width: '100%',
                                aspectRatio: '1/1',
                                backgroundColor: '#f8f9fa',
                                border: '2px dashed #ccc',
                                cursor: 'pointer',
                                borderRadius: '10px',
                                overflow: 'hidden',
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                        >
                            <i className="bi bi-plus-lg text-muted" style={{ fontSize: '1.5rem' }}></i>
                            <span className="text-muted" style={{ fontSize: '1.5rem' }}>+</span>
                        </label>
                        <input
                            type="file"
                            id="addImage"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleAddNewImage}
                        />
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};