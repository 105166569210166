import React from 'react';
import logo from '../../media/cay_logo.png';

// Simple ImageLoader component with smaller logo inside the spinner
const ImageLoader = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40px',
                height: '40px',
            }}
        >
            {/* Spinning loader circle */}
            <div
                className="loader"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    border: '4px solid rgba(0, 0, 0, 0.1)',
                    borderLeftColor: '#000',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite',
                }}
            />
            {/* Logo inside the spinner */}
            <img
                src={logo}
                alt="Logo"
                style={{
                    paddingTop: "8%",
                    maxWidth: '50%', // Adjust the size of the logo as a percentage of the spinner
                    maxHeight: '50%', // Keep the logo in its original aspect ratio
                }}
            />
        </div>
    );
};

// CSS for spinner animation
const loaderStyle = `
    @keyframes spin {
        to { transform: rotate(360deg); }
    }
`;

// Inject the loader style dynamically
const style = document.createElement('style');
style.innerHTML = loaderStyle;
document.head.appendChild(style);

export default ImageLoader;