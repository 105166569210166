import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import confetti from 'canvas-confetti';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  updateDoc,
  addDoc,
  collection,
  storage,
  db,
  auth
} from "../config/firebase";
import { useNavigate } from 'react-router-dom';

import {
  BsInstagram,
  BsFacebook,
  BsTiktok,
  BsYoutube,
  BsGlobe
} from "react-icons/bs";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required").max(500, 'Description is too long'),
  resume: yup.array()
    .of(
      yup.object().shape({
        preview: yup.string().required(),
        id: yup.string().required(),
      })
    )
    .min(4, 'Exactly 4 images are required')
    .max(4, 'Exactly 4 images are required'),
  pdfPortfolio: yup.mixed()
    .required('PDF Portfolio is required')
    .test("fileType", "Only PDF is allowed", (value) => value && value.type === "application/pdf"),
  email: yup.string().email("Invalid email").required("Email is required"),
  legalName: yup.string().required("Full Legal Name is required"),
  telephone: yup.string().required("Telephone Number is required"),
  address1: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  zip: yup.string().required("ZIP Code is required"),
  countryCode: yup.string().length(2, 'Country code must be 2 characters').required("Country Code is required"),
  instagramHandle: yup.string(),
  facebookHandle: yup.string(),
  tiktokHandle: yup.string(),
  youtubeHandle: yup.string(),
  websiteUrl: yup.string().url('Must be a valid URL').notRequired(),
  handmade: yup.string().oneOf(['Yes', 'No'], 'Please select if your products are handmade').required('This field is required'),
  soldBefore: yup.string().oneOf(['To friends/family', 'Yes, many times', 'No'], 'Please select if you have sold your products before').required('This field is required'),
  terms: yup.bool().oneOf([true], 'Must Accept Terms and Conditions').required('You must accept the terms and conditions'),
});

export const Apply = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const navigate = useNavigate();

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      setFiles(prevFiles => [
        ...prevFiles,
        ...acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: uuidv4()
        }))
      ]);
    },
  });

  const { register, handleSubmit, formState: { errors }, reset, setValue, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
      setValue("pdfPortfolio", file, { shouldValidate: true });
    } else {
      setPdfFile(null);
      setValue("pdfPortfolio", null, { shouldValidate: true });
    }
  };

  const onSubmit = async (data) => {
    // Immediately disable the button
    setIsLoading(true);

    // If no pdfFile is set, show error and re-enable the button
    if (!pdfFile) {
      setError("pdfPortfolio", { type: "manual", message: "PDF Portfolio is required" });
      setIsLoading(false);
      return;
    }

    data = { ...data, resume: files, pdfPortfolio: pdfFile };

    const newEntry = {
      name: data.name,
      description: data.description,
      handmade: data.handmade,
      soldBefore: data.soldBefore,
      email: data.email,
      legalName: data.legalName,
      telephone: data.telephone,
      address1: data.address1,
      city: data.city,
      zip: data.zip,
      countryCode: data.countryCode,
      socialMedia: {
        instagram: data.instagramHandle || '',
        facebook: data.facebookHandle || '',
        tiktok: data.tiktokHandle || '',
        youtube: data.youtubeHandle || '',
        website: data.websiteUrl || ''
      },
      timestamp: new Date(),
      googleName: auth.currentUser.displayName,
      googleEmail: auth.currentUser.email,
      googleUserId: auth.currentUser.uid,
    };

    try {
      const userId = auth.currentUser.uid;
      const docRef = await addDoc(collection(db, "users", userId, "applications"), newEntry);

      // Upload image files to Storage
      let uploadedFileURLs = [];
      for (let file of data.resume) {
        const storageRef = ref(storage, `users/${userId}/applications/${docRef.id}/${file.id}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        uploadedFileURLs.push(downloadURL);
      }

      await updateDoc(docRef, { fileURLs: uploadedFileURLs });

      // Upload PDF to Storage
      if (pdfFile) {
        const pdfRef = ref(storage, `users/${userId}/applications/${docRef.id}/portfolio.pdf`);
        await uploadBytes(pdfRef, pdfFile);
        const pdfDownloadURL = await getDownloadURL(pdfRef);
        await updateDoc(docRef, { pdfPortfolioURL: pdfDownloadURL });
      }

      // Launch confetti on successful submission
      confetti({
        particleCount: 500,
        spread: 200,
        origin: { y: 0.6 }
      });

      // Reset the form
      setFiles([]);
      setPdfFile(null);
      reset();

      // Navigate to home page after successful submission
      navigate('/');
    } catch (error) {
      console.error("Error submitting form:", error);
      // Re-enable button if error occurs
      setIsLoading(false);
    }
  };

  const removeFile = (fileToRemove) => {
    setFiles(currentFiles => currentFiles.filter(file => file.id !== fileToRemove.id));
  };

  return (
    <div className="container-fluid p-4" style={{ backgroundColor: 'transparent', minHeight: '100vh' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-center align-items-start">
          {/* Left Column - Creator Related */}
          <div className="col-lg-5 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="form-group mb-4">
                  <label htmlFor="creatorName"><strong>Creator Name</strong></label>
                  <input
                    {...register("name")}
                    type="text"
                    className="form-control"
                    id="creatorName"
                    placeholder="Your creator name"
                  />
                  <small className="text-muted d-block mt-2">This will be visible to customers on the web shop and <strong>cannot be changed later</strong>.</small>
                  <p className="text-danger mt-2">{errors.name?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="description">Describe Your Creations</label>
                  <textarea
                    {...register("description")}
                    className="form-control"
                    id="description"
                    rows="4"
                    placeholder="Describe your creations..."
                  />
                  <small className="text-muted d-block mt-2">Visible to customers. You can change this later.</small>              
                  <p className="text-danger mt-2">{errors.description?.message}</p>
                </div>
              </div>
            </div>

            <div className="card shadow-sm mt-4">
              <div className="card-body">
                <div className="form-group mb-4">
                  <label htmlFor="handmade">Are your products handmade?</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        {...register("handmade")}
                        className="form-check-input"
                        type="radio"
                        id="handmadeYes"
                        value="Yes"
                      />
                      <label className="form-check-label" htmlFor="handmadeYes">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        {...register("handmade")}
                        className="form-check-input"
                        type="radio"
                        id="handmadeNo"
                        value="No"
                      />
                      <label className="form-check-label" htmlFor="handmadeNo">No</label>
                    </div>
                  </div>
                  <p className="text-danger mt-2">{errors.handmade?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="soldBefore">Have you ever sold your products before?</label>
                  <select className="form-control" id="soldBefore" {...register("soldBefore")}>
                    <option>To friends/family</option>
                    <option>Yes, many times</option>
                    <option>No</option>
                  </select>
                  <p className="text-danger mt-2">{errors.soldBefore?.message}</p>
                </div>
              </div>
            </div>

            <div className="card shadow-sm mt-4" {...getRootProps()}>
              <div className="card-body">
                <label htmlFor="portfolio">Upload Previous Work Images (Exactly 4)</label>
                <input {...getInputProps()} />
                <div className="form-text text-center border border-dashed rounded p-4 bg-light">
                  Drag 'n' drop images here, or click to select files
                </div>
                <button type="button" onClick={open} className="btn btn-outline-primary mt-2">
                  Select Files
                </button>
                <small className="text-muted d-block mt-2">Visible to customers. You can change this later.</small>
                <div className="mt-3">
                  {files.map(file => (
                    <div key={file.id} className="d-flex align-items-center mb-2">
                      <img src={file.preview} alt="preview" className="img-thumbnail me-2" width={100} height={100} />
                      <button onClick={() => removeFile(file)} className="btn btn-danger btn-sm">Remove</button>
                    </div>
                  ))}
                </div>
                <p className="text-danger mt-2">{errors.resume?.message}</p>
              </div>
            </div>

            <div className="card shadow-sm mt-4">
              <div className="card-body">
                <div className="form-group mb-4">
                  <label htmlFor="pdfPortfolio">Upload PDF Portfolio</label>
                  <input
                    type="file"
                    className="form-control"
                    id="pdfPortfolio"
                    accept="application/pdf"
                    onChange={handlePdfUpload}
                  />
                  <p className="text-danger mt-2">{errors.pdfPortfolio?.message}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Personal & Social Information */}
          <div className="col-lg-5 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="form-group mb-4">
                  <label htmlFor="email">Email</label>
                  <input
                    {...register("email")}
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                  />
                  <p className="text-danger mt-2">{errors.email?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="legalName">Full Legal Name</label>
                  <input
                    {...register("legalName")}
                    type="text"
                    className="form-control"
                    id="legalName"
                    placeholder="Your full legal name"
                  />
                  <p className="text-danger mt-2">{errors.legalName?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="telephone">Telephone Number</label>
                  <input
                    {...register("telephone")}
                    type="tel"
                    className="form-control"
                    id="telephone"
                    placeholder="Your phone number"
                  />
                  <p className="text-danger mt-2">{errors.telephone?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="address1">Address</label>
                  <input
                    {...register("address1")}
                    type="text"
                    className="form-control"
                    id="address1"
                    placeholder="Street address including number"
                  />
                  <p className="text-danger mt-2">{errors.address1?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="city">City</label>
                  <input
                    {...register("city")}
                    type="text"
                    className="form-control"
                    id="city"
                    placeholder="City"
                  />
                  <p className="text-danger mt-2">{errors.city?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="zip">ZIP Code</label>
                  <input
                    {...register("zip")}
                    type="text"
                    className="form-control"
                    id="zip"
                    placeholder="ZIP or Postal Code"
                  />
                  <p className="text-danger mt-2">{errors.zip?.message}</p>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="countryCode">Country Code</label>
                  <input
                    {...register("countryCode")}
                    type="text"
                    className="form-control"
                    id="countryCode"
                    placeholder="Two-letter country code"
                  />
                  <p className="text-danger mt-2">{errors.countryCode?.message}</p>
                </div>
              </div>
            </div>

            {/* Social Media & Website */}
            <div className="card shadow-sm mt-4">
              <div className="card-body">
                <div className="form-group mb-4">
                  <label>Social Media & Website</label>

                  <div className="d-flex align-items-center mb-3">
                    <BsInstagram className="me-2" />
                    <input
                      {...register("instagramHandle")}
                      type="text"
                      className="form-control"
                      placeholder="@yourinstagram"
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <BsFacebook className="me-2" />
                    <input
                      {...register("facebookHandle")}
                      type="text"
                      className="form-control"
                      placeholder="Facebook URL"
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <BsTiktok className="me-2" />
                    <input
                      {...register("tiktokHandle")}
                      type="text"
                      className="form-control"
                      placeholder="@yourtiktok"
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <BsYoutube className="me-2" />
                    <input
                      {...register("youtubeHandle")}
                      type="text"
                      className="form-control"
                      placeholder="YouTube URL"
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <BsGlobe className="me-2" />
                    <input
                      {...register("websiteUrl")}
                      type="text"
                      className="form-control"
                      placeholder="Website URL"
                    />
                  </div>
                  <p className="text-danger mt-2">{errors.websiteUrl?.message}</p>
                </div>
              </div>
            </div>

            {/* Stripe Connect */}
            <div className="card shadow-sm mt-4">
              <div className="card-body">
                <div className="form-group mb-4">
                  <label>Stripe Connect</label>
                  <button type="button" className="btn btn-outline-info w-100 py-2">
                    Connect with Stripe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Terms and Submit Button */}
        <div className="text-center mb-5">
          <div className="form-check mb-4 d-inline-block">
            <input
              type="checkbox"
              className="form-check-input"
              {...register("terms")}
              id="termsCheckbox"
            />
            <label className="form-check-label" htmlFor="termsCheckbox">
              I agree to the{' '}
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                className="text-reset text-light"
              >
                Terms of Service
              </a>
              .
            </label>
          </div>
          <p className="text-danger">{errors.terms?.message}</p>

          <button
            type="submit"
            className="btn btn-primary btn-lg shadow-sm"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};