import React, { useState, useEffect } from 'react';
import '../css/Cookie.css'; // Your custom styles, if needed

export const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');
    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('hasAcceptedCookies', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="alert alert-info fixed-bottom text-center mb-0" role="alert">
      <div className="container">
        <span>
          We probably use cookies to ensure you get the best experience on our website. By using our site, you agree to our use of cookies.
        </span>
        <div className="mt-2">
          <a href="/cookies-policy" className="alert-link" target="_blank" rel="noopener noreferrer">
            Learn more
          </a>
        </div>
        <button onClick={handleAccept} className="btn btn-primary mt-3">
          Got it!
        </button>
      </div>
    </div>
  );
};