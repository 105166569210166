import React from 'react';
import { Link } from 'react-router-dom';

const ContentSection = () => {
  return (
    <section
      id="section6"
      className="p-3 mb-5 rounded shadow-sm"
      style={{ scrollMarginTop: "65px", backgroundColor: "rgba(248, 249, 250, 0.8)" }}
    >
      
      <div className="container">
        <h2 className="display-5">Content</h2>
        <p className="lead">
          Manage the visual and textual content of your store.
        </p>
        <Link to="/content" className="btn btn-dark">Manage Content</Link>
      </div>
    </section>
  );
};

export default ContentSection;