import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/slices/productsSlice'; // Adjust the path as needed
import { Link } from 'react-router-dom';
import { SkeletonProductLoader } from '../components/loaders/SkeletonProductLoader'; // Import the skeleton loader

export const AllProductsPage = () => {
    const productsState = useSelector((state) => state.products);
    const { products = [], status = 'idle' } = productsState;

    if (status === 'loading') {

        return (
        <div className="container mt-4 d-flex flex-column" style={{ paddingBottom: '100px' }}>
        <div className="row flex-grow-1">
        <SkeletonProductLoader />
        </div>
        </div>


        );
    }

    if (status === 'failed') {
        console.log('Failed to load products.');
        return <div>Failed to load products.</div>;
    }

    return (
        <div className="container mt-4 d-flex flex-column" style={{ paddingBottom: '100px' }}>
            <div className="row flex-grow-1">
                {products.length > 0 ? (
                    products.map((product) => (
                        <div key={product.id} className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4">
                            <div className="card h-100 shadow-sm">
                                <Link to={`/edit-product/${product.id}`} className="text-decoration-none text-dark">
                                    <img 
                                        src={product.image?.src || 'https://via.placeholder.com/150'} 
                                        className="card-img-top rounded-top" 
                                        alt={product.title} 
                                        style={{ objectFit: 'cover', height: '200px' }} 
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">{product.title}</h5>
                                        <p className="card-text">
                                            {product.price ? `$${product.price}` : 'Price not available'}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <SkeletonProductLoader />
                )}
            </div>
        </div>
    );
};