// src/components/SuccessPopup.js

import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Import an icon from react-icons

export const SuccessPopup = ({ show, onClose }) => {
    if (!show) return null;

    return (
        <div
            className="modal show fade"
            tabIndex="-1"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content" style={{ borderRadius: '10px' }}>
                    <div className="modal-body text-center p-4">
                        <FaCheckCircle size={60} color="#28a745" className="mb-3" />
                        <h4 className="mb-3">Product Added Successfully!</h4>
                        <p>
                            Your product has been <strong>added</strong> and is awaiting review before it goes live on the website.
                            You can edit your products in the "Edit Products" section.
                        </p>
                        <button type="button" className="btn btn-primary mt-3" onClick={onClose}>
                            Okay
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};