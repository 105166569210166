// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import ordersReducer from './slices/ordersSlice';
import productsReducer from './slices/productsSlice';
import addProductReducer from './slices/addProductSlice'; // Import the addProduct slice

export const store = configureStore({
  reducer: {
    user: userReducer,
    orders: ordersReducer,
    products: productsReducer,
    addProduct: addProductReducer,
  },
});

export default store;