// src/components/ContentManager.js

import React, { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase'; // Adjust the path as needed
import { useSelector } from 'react-redux';

export const ContentManager = () => {
  const [content, setContent] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const MAX_VIDEO_SIZE = 1024 * 1024 * 1024; // 1 GB
  const MAX_IMAGE_SIZE = 100 * 1024 * 1024;  // 100 MB

  // Get creatorName from Redux
  const creatorName = useSelector((state) => state.user.creatorName);

  const handleAddContent = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Reset error message
      setErrorMessage('');

      // Check file type and size
      if (file.type.startsWith('image/')) {
        if (file.size > MAX_IMAGE_SIZE) {
          setErrorMessage('Image size should be less than 100 MB.');
          return;
        }
      } else if (file.type.startsWith('video/')) {
        if (file.size > MAX_VIDEO_SIZE) {
          setErrorMessage('Video size should be less than 1 GB.');
          return;
        }
      } else {
        setErrorMessage('Unsupported file type. Please upload an image or video file.');
        return;
      }

      setContent(file);
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    }
  };

  const handleDelete = () => {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    setContent(null);
    setPreview(null);
    setErrorMessage('');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      // Reset error message
      setErrorMessage('');

      // Check file type and size
      if (file.type.startsWith('image/')) {
        if (file.size > MAX_IMAGE_SIZE) {
          setErrorMessage('Image size should be less than 100 MB.');
          return;
        }
      } else if (file.type.startsWith('video/')) {
        if (file.size > MAX_VIDEO_SIZE) {
          setErrorMessage('Video size should be less than 1 GB.');
          return;
        }
      } else {
        setErrorMessage('Unsupported file type. Please upload an image or video file.');
        return;
      }

      setContent(file);
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleUpload = async () => {
    if (!content) {
      setErrorMessage('Please select a file to upload.');
      return;
    }

    if (!creatorName) {
      setErrorMessage('User is not authenticated or creatorName is not available.');
      return;
    }

    setUploading(true);
    setErrorMessage('');

    const fileRef = ref(storage, `userContent/${creatorName}/${content.name}`);

    try {
      await uploadBytes(fileRef, content);
      const url = await getDownloadURL(fileRef);
      console.log('Uploaded file:', url);
      // Display success message
      alert('File uploaded successfully!');
      handleDelete();
    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorMessage('Failed to upload file. Please try again later.');
    } finally {
      setUploading(false);
    }
  };

  const renderPreview = () => {
    if (content) {
      if (content.type.startsWith('image/')) {
        return (
          <img
            src={preview}
            alt="Selected content"
            className="d-block w-100 h-100"
            style={{ objectFit: 'cover' }}
          />
        );
      } else if (content.type.startsWith('video/')) {
        return (
          <video
            src={preview}
            className="d-block w-100 h-100 video-preview"
            controls
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="container p-4 mb-4 rounded" style={{ backgroundColor: '#f0f0f0' }}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 mb-3">
          {/* Upload Guidelines */}
          <div className="mb-3">
            <p className="small text-muted">
              <strong>Upload Guidelines:</strong><br />
              - Supported file types: JPG, PNG, GIF images; MP4, MOV videos.<br />
              - Maximum image size: 100 MB.<br />
              - Maximum video size: 1 GB.<br />
              - Please only include content you want us to use.
            </p>
          </div>

          {/* Error Message */}
          {errorMessage && (
            <div className="alert alert-danger">
              {errorMessage}
            </div>
          )}

          <div
            className={`card shadow-sm position-relative ${
              isDragging ? 'border border-primary' : ''
            }`}
            style={{
              width: '100%',
              aspectRatio: '1 / 1',
              borderRadius: '10px',
              overflow: 'hidden',
              backgroundColor: '#f8f9fa',
              border: content ? 'none' : '2px dashed #ccc',
              cursor: 'pointer',
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {content ? (
              <>
                {renderPreview()}
                <button
                  type="button"
                  className="btn btn-danger btn-sm position-absolute"
                  style={{
                    top: '5px',
                    right: '5px',
                    width: '30px',
                    height: '30px',
                    borderRadius: '5px', // Square with rounded corners
                    padding: '0',
                    fontSize: '1rem',
                  }}
                  onClick={handleDelete}
                >
                  &times;
                </button>
              </>
            ) : (
              <label
                htmlFor="addContent"
                className="d-flex flex-column justify-content-center align-items-center h-100"
                style={{ cursor: 'pointer' }}
              >
                <i className="bi bi-plus-lg text-muted" style={{ fontSize: '3rem' }}></i>
                <span className="text-muted" style={{ fontSize: '0.9rem' }}>
                  Upload Image or Video
                </span>
              </label>
            )}
            <input
              type="file"
              id="addContent"
              accept="image/*,video/*"
              style={{ display: 'none' }}
              onChange={handleAddContent}
            />
          </div>

          {/* Upload Button */}
          {content && (
            <div className="text-center mt-3">
              <button
                className="btn btn-primary"
                onClick={handleUpload}
                disabled={uploading}
              >
                {uploading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentManager;